import { randomize } from '../../helpers/prototype-extensions.js';

import { BUILDINGS, IMAGES } from 'common/consts/types/index.js';

import { BuildingBase } from './buildings-base.js';

export class Forge extends BuildingBase {
  constructor(params) {
    super({ ...params, uncolorable: true });

    this.type = BUILDINGS.FORGE;
  }

  getSpriteName() {
    return IMAGES.BUILDINGS.FORGE;
  }

  onCycleEnd() {
    if (this.owner && this.advancedLogicPart) this.increaseFortification();
  }

  increaseFortification() {
    const allFields = this.game.mapController.getAllFields().filter((field) => field.ownedBy(this.owner));

    const randomField = randomize(allFields)[0];

    if (!randomField) return;

    randomField.changeFortification(1);
  }
}
