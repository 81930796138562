import * as PIXI from 'pixi.js';

import { SHUFFLED_CARD_SPEED } from 'client/consts/interface.js';
import { CARD_SCALE, CARD_WIDTH, DECK_BOTTOM, DECK_HEIGHT, DECK_LEFT, DECK_WIDTH, MARGIN } from 'client/consts/layout.js';
import { SOUNDS } from 'client/consts/sounds.js';
import { Z_INDEX } from 'client/consts/z-index.js';
import { withLeftClick } from 'client/utils/with-left-click.js';

import { IMAGES } from 'common/consts/types/index.js';
import { Point } from 'common/data-types/point.js';
import { random, sleep } from 'common/helpers/index.js';

import { InterfaceBase } from '../interface-base.js';

const FIRST_REACHING_DECK = 'FIRST_REACHING_DECK';
const NEXT_REACHING_DECK = 'NEXT_REACHING_DECK';
const REACHING_OUTER = 'REACHING_OUTER';

export class Deck extends InterfaceBase {
  constructor(params) {
    super(params);

    this.kingdom = params.kingdom;
    this.parent = params.parent;

    this.redrawable = false;
    this.clickable = false;

    this.interactive = false;
    this.interactiveBlockades = 1;

    this.isShuffiling = false;
    this.cardsStatus = null;

    this.createSprites();
    this.setSpritesPosition();
  }

  createSprites() {
    this.createBottomSpritesContainer();
    this.createTopSpritesContainer();

    this.createEmptySprites();
    this.createTextSprites();
  }

  createBottomSpritesContainer() {
    this.bottomContainer = new PIXI.Container();
    this.bottomContainer.zIndex = Z_INDEX.DECK.BOTTOM;

    this.registerSprite(this.bottomContainer);
  }

  createTopSpritesContainer() {
    this.topContainer = new PIXI.Container();
    this.topContainer.zIndex = Z_INDEX.DECK.TOP;

    this.topContainer.cursor = 'pointer';

    this.topContainer.on('pointerdown', withLeftClick(this.onClick));

    this.registerSprite(this.topContainer);
  }

  createTextSprites() {
    this.clickMask = this.game.texturesManager.createStandardSprite(IMAGES.ENTITIES.EMPTY_DECK);
    this.clickMask.x = 0;
    this.clickMask.y = 0;
    this.clickMask.scale.set(CARD_SCALE);
    this.clickMask.alpha = 0;

    this.quantityText = new PIXI.Text({
      text: '',
      style: {
        fontFamily: 'Arial',
        fontSize: 30,
        fill: 0xffffff,
      },
    });
    this.quantityText.x = 0;
    this.quantityText.y = 0;
    this.quantityText.anchor.set(0.5);

    this.topContainer.addChild(this.clickMask);
    this.topContainer.addChild(this.quantityText);
  }

  createEmptySprites() {
    this.emptySprite = this.game.texturesManager.createStandardSprite(IMAGES.ENTITIES.EMPTY_DECK);
    this.emptySprite.x = 0;
    this.emptySprite.y = 0;
    this.emptySprite.scale.set(CARD_SCALE);

    this.bottomContainer.addChild(this.emptySprite);
  }

  updateQuantitySprite(cards) {
    const qunatity = cards.length;
    this.quantityText.text = qunatity;
  }

  setSpritesPosition() {
    this.position = new Point(DECK_WIDTH / 2 + DECK_LEFT, window.innerHeight - DECK_HEIGHT / 2 - DECK_BOTTOM);

    this.bottomContainer.x = this.position.x;
    this.bottomContainer.y = this.position.y;

    this.topContainer.x = this.position.x;
    this.topContainer.y = this.position.y;
  }

  activate = (delta) => {
    this.updateShuffleAnimation();
  };

  updateShuffleAnimation = () => {
    if (!this.isShuffiling) return;

    const shufflingTimer = this.kingdom.getShufflingTimer();

    if (this.kingdom.getDrawPile().length <= 1) return;
    if (shufflingTimer <= 0) return;
    if (!this.areCardsInDestination()) return;

    if (this.cardsStatus === FIRST_REACHING_DECK) {
      this.setShufflingCardSpeed();
    }

    if (this.cardsStatus === NEXT_REACHING_DECK || this.cardsStatus === FIRST_REACHING_DECK) {
      if (shufflingTimer > 20) {
        this.cardsStatus = REACHING_OUTER;
        this.directCardsToOutsidePosition();
        this.game.soundsController.playSound(SOUNDS.SHUFFLING, { speedRange: 0.2 });
      } else {
        this.onShufflingEnd();
      }
    } else if (this.cardsStatus === REACHING_OUTER) {
      this.cardsStatus = NEXT_REACHING_DECK;
      this.directCardsToDeckPosition();
    }
  };

  onInitialize() {
    const cards = this.kingdom.getDrawPile();

    this.setCardsStartingPositions(cards);
    this.updateQuantitySprite(cards);
    this.updateCardsInterfaces(cards);
  }

  onChange() {
    const cards = this.kingdom.getDrawPile();

    this.directCardsToDeckPosition(cards);
    this.updateQuantitySprite(cards);
    this.updateCardsInterfaces(cards);
  }

  updateCardsInterfaces(cards) {
    cards.forEach((card) => {
      card.setContainer(this);
      card.showBack();
      card.setInteractive(false);
      card.setZIndex(Z_INDEX.DECK.CARDS);
    });
  }

  onShufflingStart = () => {
    this.onChange();

    this.isShuffiling = true;
    this.cardsStatus = FIRST_REACHING_DECK;
  };

  onShufflingEnd = () => {
    this.isShuffiling = false;

    const cards = this.kingdom.getDrawPile();
    cards.forEach((card) => {
      card.resetSpeed();
    });
  };

  destroy() {
    super.destroy();
    this.removeSprite(this.bottomContainer);
    this.removeSprite(this.topContainer);
  }

  afterResize() {
    this.setSpritesPosition();
    this.setCardsToDeckPosition();
  }

  onClick = () => {
    if (!this.interactive) return false;

    this.parent.onDeckClick();
  };

  onRedrawChange() {
    const canRedraw = this.kingdom.getCanRedraw();
    this.setRedrawable(canRedraw);
  }

  setRedrawable(value) {
    if (value === this.redrawable) return false;

    this.redrawable = value;

    this.detectClickable();
  }

  blockInteractive() {
    this.interactiveBlockades++;
    if (this.interactiveBlockades === 1) this.setInteractive(false);
  }

  unblockInteractive() {
    this.interactiveBlockades--;
    if (this.interactiveBlockades === 0) this.setInteractive(true);
  }

  setInteractive(value) {
    if (value === this.interactive) return false;

    this.interactive = value;

    this.detectClickable();
  }

  detectClickable() {
    this.setClickable(this.interactive && this.redrawable);
  }

  setClickable(value) {
    if (value === this.clickable) return false;

    this.clickable = value;

    this.topContainer.eventMode = value ? 'static' : 'passive';
  }

  waitForCardsToReachDestiny = async (cards) => {
    while (true) {
      const cardsToReach = cards.filter((card) => !card.isOnDestiny()).length;

      if (cardsToReach === 0) return true;
      await sleep(10);
    }
  };

  areCardsInDestination = () => {
    const cards = this.kingdom.getDrawPile();
    return cards.filter((card) => !card.isOnDestiny()).length === 0;
  };

  directCardsToDeckPosition() {
    const cards = this.kingdom.getDrawPile();

    cards.forEach((card) => {
      card.setDestination(this.position.clone());
    });
  }

  setCardsToDeckPosition() {
    const cards = this.kingdom.getDrawPile();

    cards.forEach((card) => {
      card.setPosition(this.position.clone());
    });
  }

  directCardsToOutsidePosition() {
    const cards = this.kingdom.getDrawPile();

    cards.forEach((card, index) => {
      card.setDestination(
        this.position
          .clone()
          .addX(((index % 2 ? -1 : 1) * (CARD_WIDTH + MARGIN)) / 2)
          .addY(random(-10, 10))
      );
    });
  }

  setCardsStartingPositions(cards) {
    cards.forEach((card) => {
      card.setPosition(this.position.clone());
    });
  }

  setShufflingCardSpeed() {
    const cards = this.kingdom.getDrawPile();
    cards.forEach((card) => {
      card.setSpeed(SHUFFLED_CARD_SPEED);
    });
  }

  resetCardSpeed() {
    const cards = this.kingdom.getDrawPile();
    cards.forEach((card) => {
      card.resetSpeed();
    });
  }

  hideCards() {
    const cards = this.kingdom.getDrawPile();
    cards.forEach((card) => {
      card.setSpeed(SHUFFLED_CARD_SPEED);
    });
  }
}
