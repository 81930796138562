import { uniqueString } from 'common/helpers/data.js';

import {
  BASE_BUILDING_INACCURACY,
  DEFENDER_NUMBER_OF_BULLETS,
  DEFENDER_RANGE,
  MINE_DAMAGE,
  MINE_RANGE,
  POWER_PLANT_PRODUCTION,
  RANDOM_CANNON_INACCURACY,
  SHORT_RANGE_CANNON_RANGE,
} from './buildings.js';

export const TOOLTIP = {
  CATEGORY: {
    EFFECT: uniqueString(),
    BUILDING: uniqueString(),
    CARD: uniqueString(),
  },
  TYPE: {
    VOID: uniqueString(),
    LIGHTWEIGHT: uniqueString(),
    POWER_STATION: uniqueString(),
    DRAIN: uniqueString(),
    SPREAD: uniqueString(),
    SNIPER: uniqueString(),
    FORTIFICATION: uniqueString(),
    BASIC_CANNON: uniqueString(),
    SNIPER_CANNON: uniqueString(),
    FORGE: uniqueString(),
    MINE: uniqueString(),
    RANDOM_CANNON: uniqueString(),
    POWER: uniqueString(),
    PIERCING: uniqueString(),
    DEFENDER: uniqueString(),
    SHORT_RANGE_CANNON: uniqueString(),
  },
};

export const TOOLTIP_TEXTS = {
  [TOOLTIP.TYPE
    .VOID]: `<b>Void X</b> - every cycle, this effect inflicts damage on fields, beginning with the one farthest from the base, continuing until it has dealt a total of X damage.`,
  [TOOLTIP.TYPE.LIGHTWEIGHT]: '<b>Lightweight</b> - this card is not counted when you draw any number of cards.',
  [TOOLTIP.TYPE.SPREAD]: '<b>Spread X</b> - fire X bullets a the same time.',
  [TOOLTIP.TYPE.SNIPER]: '<b>Sniper</b> - shot without any inaccuracy.',
  [TOOLTIP.TYPE.FORTIFICATION]: '<b>Fortification X</b> - protects a field from X damage.',
  [TOOLTIP.TYPE.POWER_STATION]: `<b>Power station</b> - building which produces ${POWER_PLANT_PRODUCTION} energy every redraw.`,
  [TOOLTIP.TYPE.DRAIN]: '<b>Drain X</b> - card with this ability can be played only after paying X energy.',
  [TOOLTIP.TYPE
    .BASIC_CANNON]: `<b>Basic Cannon</b> - a building that shots 1 bullet with ${BASE_BUILDING_INACCURACY} inaccuracy every cycle.`,
  [TOOLTIP.TYPE.SNIPER_CANNON]: '<b>Sniper Cannon</b> - a building that shots 1 bullet without inaccuracy every cycle.',
  [TOOLTIP.TYPE.FORGE]: '<b>Forge</b> - a building that generates 1 fortification to own field every cycle.',
  [TOOLTIP.TYPE
    .MINE]: `<b>Mine</b> - an object that detonates when its field is captured. On detonation it inflicts ${MINE_DAMAGE} damage to all fields up to ${MINE_RANGE} spaces away.`,
  [TOOLTIP.TYPE
    .RANDOM_CANNON]: `<b>Random Cannon</b> - a cannon that shots 1 bullet with ${RANDOM_CANNON_INACCURACY} inaccuracy every cycle.`,
  [TOOLTIP.TYPE.PIERCING]: '<b>Piercing X</b> - allows bullet to go through up to X enemy fields.',
  [TOOLTIP.TYPE.POWER]: '<b>Power X</b> - allows bullet to deal up to X damage in total.',
  [TOOLTIP.TYPE
    .DEFENDER]: `<b>Defender</b> - a building that shots ${DEFENDER_NUMBER_OF_BULLETS} bullets - one towarts every adjacent field, every cycle. The range is ${DEFENDER_RANGE} spaces.`,
  [TOOLTIP.TYPE
    .SHORT_RANGE_CANNON]: `<b>Short Range Cannon</b> - a building that shots 1 bullet with ${BASE_BUILDING_INACCURACY} inaccuracy and ${SHORT_RANGE_CANNON_RANGE} spaces range every cycle.`,
};
