import { POWER_PLANT_PRODUCTION } from '../../../consts/buildings.js';

import { BUILDINGS, IMAGES } from 'common/consts/types/index.js';

import { BuildingBase } from '../buildings-base.js';

export class PowerPlant extends BuildingBase {
  constructor(params) {
    super({ ...params, uncolorable: true });

    this.type = BUILDINGS.POWER_STATION;

    this.owner.changeEnergyProduction(POWER_PLANT_PRODUCTION);
  }

  getSpriteName() {
    return IMAGES.BUILDINGS.POWER_STATION;
  }

  onDestroy() {
    this.owner.changeEnergyProduction(-POWER_PLANT_PRODUCTION);
  }
}
