import { HEXAGON_SIZE } from 'client/consts/layout.js';

import { DEFENDER_NUMBER_OF_BULLETS, DEFENDER_RANGE } from 'common/consts/buildings.js';
import { BUILDINGS, ENTITIES, IMAGES } from 'common/consts/types/index.js';
import { degreesToRadians, perFrameSpeed, perFrameTimer, rotationToSpeeds } from 'common/helpers/index.js';

import { BasicCannon } from './basic.js';

export class DefenderCannon extends BasicCannon {
  constructor(params) {
    super(params);

    this.range = HEXAGON_SIZE * DEFENDER_RANGE;
    this.rotate = false;

    this.type = BUILDINGS.DEFENDER;
  }

  getSpriteName() {
    return IMAGES.BUILDINGS.DEFENDER;
  }

  onCycleEnd() {
    if (this.owner && this.advancedLogicPart) this.fireBullets();
  }

  fireBullets() {
    let rotation = degreesToRadians(30);

    for (let i = DEFENDER_NUMBER_OF_BULLETS; i > 0; i--) {
      this.game.entitiesController.create(ENTITIES.BULLET, {
        x: this.position.x,
        y: this.position.y,
        ...rotationToSpeeds(rotation, perFrameSpeed(this.bulletSpeed)),
        lifeTime: perFrameTimer(this.range / this.bulletSpeed),
        owner: this.owner,
      });

      rotation += degreesToRadians(360 / DEFENDER_NUMBER_OF_BULLETS);
    }
  }
}
