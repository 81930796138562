import { SHORT_RANGE_CANNON_RANGE } from '../../../consts/buildings.js';

import { HEXAGON_SIZE } from 'client/consts/layout.js';

import { BUILDINGS, IMAGES } from 'common/consts/types/index.js';

import { BasicCannon } from './basic.js';

export class ShortRangeCannon extends BasicCannon {
  constructor(params) {
    super(params);

    this.range = HEXAGON_SIZE * SHORT_RANGE_CANNON_RANGE;

    this.type = BUILDINGS.SHORT_RANGE_CANNON;
  }

  getSpriteName() {
    return IMAGES.BUILDINGS.SHORT_RANGE_CANNON;
  }
}
