import { EntityBase } from 'common/entities/entity-base.js';

export class BuildingBase extends EntityBase {
  constructor(params) {
    super(params);

    this.mapField = params.mapField;

    this.owner = this.mapField.owner;

    this.position = this.mapField.position;

    this.rotate = false;

    this.building = true;
    this.demolitionable = true;

    this.uncolorable = params.uncolorable || false;

    if (this.interfacePart) this.prepareSprite();
  }

  prepareSprite() {
    this.createSprite();
    this.colorSprite();
    this.setSpritePosition();
  }

  setSpritePosition() {
    this.sprite.x = this.position.x;
    this.sprite.y = this.position.y;
  }

  getSpriteName() {
    return '';
  }

  createSprite() {
    const name = this.getSpriteName();
    if (!name) return;

    this.sprite = this.game.texturesManager.createStandardSprite(name);
    this.sprite.eventMode = 'static';

    this.registerMapSprite(this.sprite);
  }

  colorSprite() {
    if (this.uncolorable) return;
    this.sprite.tint = this.owner ? this.owner.color : 0xffffff;
  }

  getField() {
    return this.mapField;
  }

  getPosition() {
    return this.position.clone();
  }

  onCycleEnd() {}

  activate(delta) {}

  setOwner(newOwner) {
    this.owner = newOwner;
    if (this.interfacePart) this.colorSprite();
  }

  onDamage() {
    this.mapField.onBuildingDestroy();
    this.destroy();
  }

  onDestroy() {}

  destroy() {
    this.onDestroy();

    super.destroy();

    if (this.interfacePart) this.removeMapSprite(this.sprite);
  }

  toSocketData() {
    const data = { id: this.id, type: this.type };
    if (this.owner) data.hostId = this.owner.id;
    return data;
  }
}
