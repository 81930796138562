import { Assets, BitmapText, Container, Graphics, HTMLText, Rectangle, Sprite, Texture, TilingSprite } from 'pixi.js';

import basicCannon from 'client/assets/images/buildings/cannons/basic.png';
import defenderCannon from 'client/assets/images/buildings/cannons/defender.png';
import mainCannon from 'client/assets/images/buildings/cannons/main.png';
import randomCannon from 'client/assets/images/buildings/cannons/random.png';
import shortRangeCannon from 'client/assets/images/buildings/cannons/short-range.png';
import sniperCannon from 'client/assets/images/buildings/cannons/sniper.png';
import forge from 'client/assets/images/buildings/forge.png';
import mine from 'client/assets/images/buildings/mine.png';
import powerPlant from 'client/assets/images/buildings/power-plant.png';
import warehouse from 'client/assets/images/buildings/warehouse.png';
import cardBack from 'client/assets/images/cards/back.png';
import cardBackground from 'client/assets/images/cards/background.png';
import cardImageBackground from 'client/assets/images/cards/image-background.png';
import destroyBuilding from 'client/assets/images/cards/images/buildings/destroy-building.png';
import placeBasicCannon from 'client/assets/images/cards/images/buildings/place-basic-cannon.png';
import placeDefender from 'client/assets/images/cards/images/buildings/place-defender.png';
import placeForge from 'client/assets/images/cards/images/buildings/place-forge.png';
import placeLaboratory from 'client/assets/images/cards/images/buildings/place-laboratory.png';
import placeMine from 'client/assets/images/cards/images/buildings/place-mine.png';
import placeMinesRandomly from 'client/assets/images/cards/images/buildings/place-mines-randomly.png';
import placePowerStation from 'client/assets/images/cards/images/buildings/place-power-station.png';
import placeRandomCannonRandomly from 'client/assets/images/cards/images/buildings/place-random-cannon-randomly.png';
import placeShortRangeCannon from 'client/assets/images/cards/images/buildings/place-short-range-cannon.png';
import placeSniperCannon from 'client/assets/images/cards/images/buildings/place-sniper-cannon.png';
import placeWarehouse from 'client/assets/images/cards/images/buildings/place-warehouse.png';
import draw2Cards from 'client/assets/images/cards/images/cards/draw-2-cards.png';
import increaseCardLimit from 'client/assets/images/cards/images/cards/increase-card-limit.png';
import increaseMultishot from 'client/assets/images/cards/images/cards/increase-multishot.png';
import increasePower from 'client/assets/images/cards/images/cards/increase-power.png';
import trashCard from 'client/assets/images/cards/images/cards/trash-card.png';
import poisonToAll from 'client/assets/images/cards/images/effects/poison-to-all.png';
import voidToAll from 'client/assets/images/cards/images/effects/void-to-all.png';
import voidCardImage from 'client/assets/images/cards/images/effects/void.png';
import addEnergy from 'client/assets/images/cards/images/energy/add-energy.png';
import increaseEnergyProduction from 'client/assets/images/cards/images/energy/increase-energy-production.png';
import reduceEnergyCostRandomly from 'client/assets/images/cards/images/energy/reduce-energy-cost-randomly.png';
import spark from 'client/assets/images/cards/images/energy/spark.png';
import fortificateAroundMain from 'client/assets/images/cards/images/fortification/fortificate-around-main.png';
import fortificateBorders from 'client/assets/images/cards/images/fortification/fortificate-borders.png';
import fortificateBuildings from 'client/assets/images/cards/images/fortification/fortificate-buildings.png';
import fortificateMain from 'client/assets/images/cards/images/fortification/fortificate-main.png';
import fortification from 'client/assets/images/cards/images/fortification/fortification.png';
import randomFortification from 'client/assets/images/cards/images/fortification/random-fortification.png';
import junk from 'client/assets/images/cards/images/junks/junk.png';
import energyToBullets from 'client/assets/images/cards/images/shots/energy-to-bullets.png';
import fairShot from 'client/assets/images/cards/images/shots/fair-shot.png';
import multishot from 'client/assets/images/cards/images/shots/multishot.png';
import powerShot from 'client/assets/images/cards/images/shots/power-shot.png';
import singleFire from 'client/assets/images/cards/images/shots/single-fire.png';
import sniperFire from 'client/assets/images/cards/images/shots/sniper-fire.png';
import sniperShotImproving from 'client/assets/images/cards/images/shots/sniper-shot-improving.png';
import spreadShotImproving from 'client/assets/images/cards/images/shots/spread-shot-improving.png';
import spreadShot from 'client/assets/images/cards/images/shots/spread-shot.png';
import cardOverlay from 'client/assets/images/cards/overlay.png';
import cardSelection from 'client/assets/images/cards/selection.png';
import spinner from 'client/assets/images/cards/spinner.png';
import poison from 'client/assets/images/effects/poison.png';
import voidIcon from 'client/assets/images/effects/void.png';
import backgroundTile from 'client/assets/images/entities/background-tile.png';
import buildingAura from 'client/assets/images/entities/building-aura.png';
import bullet from 'client/assets/images/entities/bullet.png';
import emptyDeck from 'client/assets/images/entities/empty-deck.png';
import fieldSelector from 'client/assets/images/entities/field-selector.png';
import field from 'client/assets/images/entities/field.png';
import smallField from 'client/assets/images/entities/small-field.png';
import target from 'client/assets/images/entities/target.png';
import wall from 'client/assets/images/entities/wall.png';
import emptyDrawDot from 'client/assets/images/icons/empty-draw-dot.png';
import emptyDrawRect from 'client/assets/images/icons/empty-draw-rect.png';
import energy from 'client/assets/images/icons/energy.png';
import fortificationIcon from 'client/assets/images/icons/fortification.png';
import fullDrawDot from 'client/assets/images/icons/full-draw-dot.png';
import fullDrawRect from 'client/assets/images/icons/full-draw-rect.png';
import hexagon from 'client/assets/images/icons/hexagon.png';
import buttonHovered from 'client/assets/images/interface/button-hovered.png';
import button from 'client/assets/images/interface/button.png';
import closeButton from 'client/assets/images/interface/close-button.png';
import marketButton from 'client/assets/images/interface/market-button.png';
import scrollbarBottomButton from 'client/assets/images/interface/scrollbar/bottom-button.png';
import scrollbarThumbBottom from 'client/assets/images/interface/scrollbar/thumb-bottom.png';
import scrollbarThumbCenter from 'client/assets/images/interface/scrollbar/thumb-center.png';
import scrollbarThumbHandler from 'client/assets/images/interface/scrollbar/thumb-handler.png';
import scrollbarThumbTop from 'client/assets/images/interface/scrollbar/thumb-top.png';
import scrollbarTopButton from 'client/assets/images/interface/scrollbar/top-button.png';
import scrollbarTrack from 'client/assets/images/interface/scrollbar/track.png';
import sliderEdge from 'client/assets/images/interface/slider/edge.png';
import sliderFilling from 'client/assets/images/interface/slider/filling.png';
import sliderHandle from 'client/assets/images/interface/slider/handle.png';

import { IMAGES } from 'common/consts/types/index.js';

export class TexturesManager {
  constructor(game) {
    this.game = game;

    this.textures = {};
  }

  loadAll = async () => {
    await Promise.all([
      this.registerBuildings(),
      this.registerEntities(),
      this.registerCards(),
      this.registerIcons(),
      this.registerInterface(),
    ]);
  };

  registerBuildings = async () => {
    await Promise.all([
      this.registerTexture(IMAGES.BUILDINGS.BASIC_CANNON, basicCannon),
      this.registerTexture(IMAGES.BUILDINGS.MAIN_BUILDING, mainCannon),
      this.registerTexture(IMAGES.BUILDINGS.RANDOM_CANNON, randomCannon),
      this.registerTexture(IMAGES.BUILDINGS.SHORT_RANGE_CANNON, shortRangeCannon),
      this.registerTexture(IMAGES.BUILDINGS.SNIPER_CANNON, sniperCannon),
      this.registerTexture(IMAGES.BUILDINGS.DEFENDER, defenderCannon),

      this.registerTexture(IMAGES.BUILDINGS.WAREHOUSE, warehouse),
      this.registerTexture(IMAGES.BUILDINGS.POWER_STATION, powerPlant),
      this.registerTexture(IMAGES.BUILDINGS.FORGE, forge),
      this.registerTexture(IMAGES.BUILDINGS.MINE, mine),
    ]);
  };

  registerEntities = async () => {
    await Promise.all([
      this.registerTexture(IMAGES.ENTITIES.BULLET, bullet),
      this.registerTexture(IMAGES.ENTITIES.EMPTY_DECK, emptyDeck),
      this.registerTexture(IMAGES.ENTITIES.TARGET, target),
      this.registerTexture(IMAGES.ENTITIES.FIELD_SELECTOR, fieldSelector),
      this.registerTexture(IMAGES.ENTITIES.FIELD, field),
      this.registerTexture(IMAGES.ENTITIES.SMALL_FIELD, smallField),
      this.registerTexture(IMAGES.ENTITIES.WALL, wall),
      this.registerTexture(IMAGES.ENTITIES.BACKGROUND_TILE, backgroundTile),
      this.registerTexture(IMAGES.ENTITIES.BUILDING_AURA, buildingAura),
    ]);
  };

  registerCards = async () => {
    await Promise.all([
      this.registerTexture(IMAGES.CARDS.BACKGROUND, cardBackground),
      this.registerTexture(IMAGES.CARDS.IMAGE_BACKGROUND, cardImageBackground),
      this.registerTexture(IMAGES.CARDS.BACK, cardBack),
      this.registerTexture(IMAGES.CARDS.OVERLAY, cardOverlay),
      this.registerTexture(IMAGES.CARDS.SELECTION, cardSelection),
      this.registerTexture(IMAGES.CARDS.SPINNER, spinner),

      this.registerTexture(IMAGES.CARDS.IMAGES.PLACE_BASIC_CANNON, placeBasicCannon),
      this.registerTexture(IMAGES.CARDS.IMAGES.PLACE_SHORT_RANGE_CANNON, placeShortRangeCannon),
      this.registerTexture(IMAGES.CARDS.IMAGES.PLACE_RANDOM_CANNON_RANDOMLY, placeRandomCannonRandomly),
      this.registerTexture(IMAGES.CARDS.IMAGES.PLACE_SNIPER_CANNON, placeSniperCannon),
      this.registerTexture(IMAGES.CARDS.IMAGES.PLACE_DEFENDER, placeDefender),
      this.registerTexture(IMAGES.CARDS.IMAGES.PLACE_WAREHOUSE, placeWarehouse),
      this.registerTexture(IMAGES.CARDS.IMAGES.PLACE_MINE, placeMine),
      this.registerTexture(IMAGES.CARDS.IMAGES.PLACE_MINES_RANDOMLY, placeMinesRandomly),
      this.registerTexture(IMAGES.CARDS.IMAGES.PLACE_POWER_STATION, placePowerStation),
      this.registerTexture(IMAGES.CARDS.IMAGES.PLACE_FORGE, placeForge),
      this.registerTexture(IMAGES.CARDS.IMAGES.PLACE_LABORATORY, placeLaboratory),

      this.registerTexture(IMAGES.CARDS.IMAGES.DESTROY_BUILDING, destroyBuilding),

      this.registerTexture(IMAGES.CARDS.IMAGES.ADD_ENERGY, addEnergy),
      this.registerTexture(IMAGES.CARDS.IMAGES.SPARK, spark),
      this.registerTexture(IMAGES.CARDS.IMAGES.INCREASE_ENERGY_PRODUCTION, increaseEnergyProduction),
      this.registerTexture(IMAGES.CARDS.IMAGES.REDUCE_ENERGY_COST_RANDOMLY, reduceEnergyCostRandomly),

      this.registerTexture(IMAGES.CARDS.IMAGES.TRASH_CARD, trashCard),
      this.registerTexture(IMAGES.CARDS.IMAGES.DRAW_CARDS, draw2Cards),
      this.registerTexture(IMAGES.CARDS.IMAGES.INCREASE_POWER, increasePower),
      this.registerTexture(IMAGES.CARDS.IMAGES.INCREASE_MULTISHOT, increaseMultishot),
      this.registerTexture(IMAGES.CARDS.IMAGES.INCREASE_CARD_LIMIT, increaseCardLimit),

      this.registerTexture(IMAGES.CARDS.IMAGES.SPREAD_SHOT, spreadShot),
      this.registerTexture(IMAGES.CARDS.IMAGES.SPREAD_SHOT_IMPROVING, spreadShotImproving),
      this.registerTexture(IMAGES.CARDS.IMAGES.SINGLE_FIRE, singleFire),
      this.registerTexture(IMAGES.CARDS.IMAGES.SNIPER_SHOT_IMPROVING, sniperShotImproving),
      this.registerTexture(IMAGES.CARDS.IMAGES.SNIPER_FIRE, sniperFire),
      this.registerTexture(IMAGES.CARDS.IMAGES.MULTISHOT, multishot),
      this.registerTexture(IMAGES.CARDS.IMAGES.ENERGY_TO_BULLETS, energyToBullets),
      this.registerTexture(IMAGES.CARDS.IMAGES.FAIR_SHOT, fairShot),
      this.registerTexture(IMAGES.CARDS.IMAGES.POWER_SHOT, powerShot),

      this.registerTexture(IMAGES.CARDS.IMAGES.POISON_TO_ALL, poisonToAll),
      this.registerTexture(IMAGES.CARDS.IMAGES.VOID, voidCardImage),
      this.registerTexture(IMAGES.CARDS.IMAGES.VOID_TO_ALL, voidToAll),

      this.registerTexture(IMAGES.CARDS.IMAGES.RANDOM_FORTIFICATION, randomFortification),
      this.registerTexture(IMAGES.CARDS.IMAGES.FORTIFICATION, fortification),
      this.registerTexture(IMAGES.CARDS.IMAGES.FORTIFICATE_BORDERS, fortificateBorders),
      this.registerTexture(IMAGES.CARDS.IMAGES.FORTIFICATE_MAIN, fortificateMain),
      this.registerTexture(IMAGES.CARDS.IMAGES.FORTIFICATE_AROUND_MAIN, fortificateAroundMain),
      this.registerTexture(IMAGES.CARDS.IMAGES.FORTIFICATE_BUILDINGS, fortificateBuildings),

      this.registerTexture(IMAGES.CARDS.IMAGES.JUNK, junk),
    ]);
  };

  registerIcons = async () => {
    await Promise.all([
      this.registerTexture(IMAGES.ICONS.ENERGY, energy),
      this.registerTexture(IMAGES.ICONS.HEXAGON, hexagon),

      this.registerTexture(IMAGES.ICONS.FORTIFICATION, fortificationIcon),

      this.registerTexture(IMAGES.ICONS.EMPTY_DRAW_DOT, emptyDrawDot),
      this.registerTexture(IMAGES.ICONS.FULL_DRAW_DOT, fullDrawDot),
      this.registerTexture(IMAGES.ICONS.EMPTY_DRAW_RECT, emptyDrawRect),
      this.registerTexture(IMAGES.ICONS.FULL_DRAW_RECT, fullDrawRect),

      this.registerTexture(IMAGES.ICONS.POISON, poison),
      this.registerTexture(IMAGES.ICONS.VOID, voidIcon),
    ]);
  };

  registerInterface = async () => {
    await Promise.all([
      this.registerTexture(IMAGES.INTERFACE.MARKET_BUTTON, marketButton),

      this.registerTexture(IMAGES.INTERFACE.SCROLLBAR.TOP_BUTTON, scrollbarTopButton),
      this.registerTexture(IMAGES.INTERFACE.SCROLLBAR.BOTTOM_BUTTON, scrollbarBottomButton),
      this.registerTexture(IMAGES.INTERFACE.SCROLLBAR.THUMB_HANDLER, scrollbarThumbHandler),
      this.registerTexture(IMAGES.INTERFACE.SCROLLBAR.THUMB_TOP, scrollbarThumbTop),
      this.registerTexture(IMAGES.INTERFACE.SCROLLBAR.THUMB_CENTER, scrollbarThumbCenter),
      this.registerTexture(IMAGES.INTERFACE.SCROLLBAR.THUMB_BOTTOM, scrollbarThumbBottom),
      this.registerTexture(IMAGES.INTERFACE.SCROLLBAR.TRACK, scrollbarTrack),

      this.registerTexture(IMAGES.INTERFACE.SLIDER.EDGE, sliderEdge),
      this.registerTexture(IMAGES.INTERFACE.SLIDER.HANDLE, sliderHandle),
      this.registerTexture(IMAGES.INTERFACE.SLIDER.FILLING, sliderFilling),

      this.registerTexture(IMAGES.INTERFACE.CLOSE_BUTTON, closeButton),

      this.registerTexture(IMAGES.INTERFACE.BUTTON, button),
      this.registerTexture(IMAGES.INTERFACE.BUTTON_HOVERED, buttonHovered),
    ]);
  };

  registerTexture = async (id, image) => {
    await Assets.load(image);
    this.textures[id] = Texture.from(image, { anisotropicLevel: 16 });
  };

  getTexture(id) {
    return this.textures[id];
  }

  createStandardSprite(textureId) {
    const sprite = this.createSprite(textureId);
    sprite.anchor.set(0.5);

    return sprite;
  }

  createOutlinedText(text = '', params = {}) {
    return new BitmapText({
      text,
      style: {
        fontFamily: 'Arial',
        fontSize: 20,
        fill: 0xffffff,
        letterSpacing: 1,
        stroke: {
          width: 5.5,
          color: '#444444',
          join: 'round',
        },
        ...params,
      },
    });
  }

  createSprite(textureId) {
    return new Sprite(this.getTexture(textureId));
  }

  createEmptySprite() {
    return new Sprite();
  }

  createRectangle(x, y, width, height) {
    return new Rectangle(x, y, width, height);
  }

  createTillingSprite(textureId, width, height) {
    return new TilingSprite({ texture: this.getTexture(textureId), width, height });
  }

  createContainer() {
    const container = new Container();
    container.zIndex = 1;

    return container;
  }

  createText(text, params) {
    return new BitmapText({ text, style: { fontFamily: 'Arial', fontSize: 30, fill: 0x222222, ...params } });
  }

  createHTMLText(text, params) {
    return new BitmapText({
      text,
      style: { fontFamily: 'Arial', fontSize: 30, fill: 0x222222, ...params },
    });
  }

  graphicIntoTexture(graphic) {
    return this.game.renderer.generateTexture(graphic);
  }

  generateBoxSprite(params) {
    const sprite = new Sprite();

    sprite.texture = this.getBoxTexture(params);

    return sprite;
  }

  getBoxTexture(params) {
    const { height, width, borderRadius = 0, borderWidth = 0, borderColor, color } = params;

    const borderlessHeight = height - borderWidth * 2;
    const borderlessWidth = width - borderWidth * 2;

    const graphic = new Graphics();

    graphic.roundRect(borderlessWidth / 2, -borderlessHeight / 2, borderlessWidth, borderlessHeight, borderRadius);
    if (borderWidth) graphic.stroke({ width: borderWidth, color: borderColor });
    graphic.fill({ color });

    return this.graphicIntoTexture(graphic);
  }

  addDebugBackgroundToContainer(container) {
    const sprite = new Sprite(Texture.WHITE);

    sprite.height = container.height;
    sprite.width = container.width;
    sprite.anchor.set(0.5, 0.5);

    container.addChild(sprite);
  }
}
