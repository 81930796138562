import * as PIXI from 'pixi.js';

import { Z_INDEX } from 'client/consts/z-index.js';
import { InterfaceBase } from 'client/interface/interface-base.js';

import { perFrameSpeed, perFrameTimer } from 'common/helpers/index.js';

const DECREASE_TEMPO = perFrameSpeed(1 / 0.5);

export class InfoText extends InterfaceBase {
  constructor(params) {
    super(params);

    this.size = params.size;
    this.zIndex = params.zIndex || Z_INDEX.INFO_TEXT;

    this.createSprites();

    this.showingTimer = null;
    this.alphaDecrease = null;

    this.setPosition();
  }

  showMessage(message, time = 1) {
    this.text.text = message;

    this.showingTimer = time ? perFrameTimer(time) : null;
    this.alphaDecrease = null;
    this.spritesContainer.visible = true;
    this.spritesContainer.alpha = 1;
  }

  fadeOutMessage() {
    this.alphaDecrease = DECREASE_TEMPO;
  }

  hideMessage() {
    this.showingTimer = null;
    this.alphaDecrease = null;
    this.spritesContainer.visible = false;
  }

  show() {
    this.showTimer = null;
    this.spritesContainer.visible = true;
    this.spritesContainer.alpha = 1;
  }

  hide() {
    this.hideMessage();
  }

  setPosition() {
    this.spritesContainer.x = 0;
    this.spritesContainer.y = 0;
  }

  createSprites() {
    this.spritesContainer = new PIXI.Container();

    this.text = new PIXI.Text({
      text: '',
      style: {
        fontFamily: 'Arial',
        fontSize: this.size,
        fill: 0xffffff,
        dropShadow: true,
        dropShadowBlur: 6,
        dropShadowDistance: 0,
      },
    });
    this.text.x = 0;
    this.text.y = 0;
    this.text.anchor.set(0.5);

    this.spritesContainer.addChild(this.text);

    this.spritesContainer.visible = false;
    this.spritesContainer.zIndex = this.zIndex;

    this.registerSprite(this.spritesContainer);
  }

  activate(delta) {
    this.updateOpacity(delta);
    this.updateShowingTimer(delta);
  }

  updateOpacity(delta) {
    if (this.alphaDecrease === null) return false;

    this.spritesContainer.alpha -= this.alphaDecrease * delta;

    if (this.spritesContainer.alpha > 0) return;

    this.alphaDecrease = null;
    this.spritesContainer.visible = false;
  }

  updateShowingTimer(delta) {
    if (this.showingTimer === null) return false;

    this.showingTimer -= delta;

    if (this.showingTimer > 0) return;

    this.showingTimer = null;
    this.alphaDecrease = DECREASE_TEMPO;
  }

  destroy() {
    super.destroy();
    this.removeSprite(this.spritesContainer);
  }

  afterResize() {
    this.setPosition();
  }
}
