import { HEXAGON_SIZE } from 'client/consts/layout.js';

import { BUILDINGS, IMAGES } from 'common/consts/types/index.js';

import { BuildingBase } from './buildings-base.js';

export class Mine extends BuildingBase {
  constructor(params) {
    super(params);

    this.building = false;
    this.demolitionable = false;

    this.type = BUILDINGS.MINE;
  }

  getSpriteName() {
    return IMAGES.BUILDINGS.MINE;
  }

  onDamage() {
    this.explode();
  }

  explode() {
    this.game.mapController.everyMapField((field) => {
      if (field.position.distance(this.position) <= HEXAGON_SIZE * 3 && !field.ownedBy(this.owner)) {
        field.getDamage(3, this.owner);
      }
    });

    this.destroy();
  }
}
